<template>
    <div class="apply">
        <van-nav-bar title="申请详情" left-text="返回" left-arrow fixed @click-left="onClickLeft"/>
        <!--1 待审核（初审），2 待复审 3 待发放 4 已发放 5 审核驳回（初审） 6 复审驳回 7 发放失败 8 已取消-->
        <div class="status" :class="'status'+ data.status" v-if="co_mode==1">
            <img :src="statusImg[data.status]" alt="">
            {{status1[data.status]}}
            <span v-if="data.status == 5">({{data.reject_reason}})</span>
            <span v-if="data.status == 6">({{data.rreject_reason}})</span>
            <span v-if="data.status == 7">({{data.fail_reason}})</span>
        </div>
        <div class="status" :class="'status'+ data.status" v-else>
            <img :src="statusImg[data.status]" alt="">
            {{status2[data.status]}}
            <span v-if="data.status == 5">({{data.reject_reason}})</span>
            <span v-if="data.status == 6">({{data.rreject_reason}})</span>
            <span v-if="data.status == 7">({{data.fail_reason}})</span>
        </div>
        <div class="applyInfo">
            <div class="title">
                申请信息
            </div>
            <div class="wrap">
                <ul>
                    <li>
                        <p class="label">申请金额</p><span>{{data.apply_money}}元</span>
                    </li>
                    <li>
                        <p class="label">申请时间</p><span>{{data.apply_time}}</span>
                    </li>
                    <li v-if="co_mode==1">
                        <p class="label">综合服务费</p><span>{{data.fee}}元</span>
                    </li>
                    <li v-if="co_mode==1">
                        <p class="label">实际到账</p><span>{{actualMoney}}元</span>
                    </li>
                    <li v-if="co_mode==2 && data.invoice_type">
                        <p class="label">发票类型</p><span>{{type[data.invoice_type]}}</span>
                    </li>
                    <li v-if="co_mode==2 && data.status !=1  && data.status !=5 && data.invoice_img">
                        <p class="label">发票照片</p><img :src="data.invoice_img" alt="">
                    </li>
                    <li v-if="co_mode==2 && data.status !=1  && data.status !=5 && data.invoice_file">
                        <p class="label">pdf</p>
                        <!-- <pdf :src="data.invoice_file"
                        :page="currentPage"
                        @num-pages="pageCount=$event"
                        @page-loaded="currentPage=$event"
                        @loaded="loadPdfHandler"> ></pdf> -->
                        <a :href="data.invoice_file">查看pdf</a>
                    </li>
                    <li v-if="co_mode==2 && data.invoice_type == 2">
                        <p class="label">快递公司</p><span>{{data.express_company}}</span>
                    </li>
                    <li v-if="co_mode==2 && data.status !=1  && data.status !=5 && data.invoice_type == 2">
                        <p class="label">快递单号</p><span>{{data.express_no}}</span>
                    </li>
                </ul>
            </div>
        </div>
<!--        <div class="sixTypes" v-for="(v,i) in applyData" :key="i">-->
<!--            <div class="title">-->
<!--                申请类型-->
<!--            </div>-->
<!--            &lt;!&ndash;推广宣传&ndash;&gt;-->
<!--            <div class="onetype" v-if="v.type==1">-->
<!--                <div class="wrap" :class="onetype?'wrapHeight':''">-->
<!--                    <div class="openImg " :class="onetype?'retractActive':'openactive'" @click="opentype(1)">-->
<!--                        <img :src="openImg" alt="">-->
<!--                    </div>-->
<!--                    <div class="typeTitle">-->
<!--                        <span>申请类型</span> <span class="name">推广宣传</span>-->
<!--                    </div>-->
<!--                    <div class="uploadBox">-->
<!--                        <img :src="v1" alt="" v-for="(v1,j) in v.data.imgs" :key="j" @click="seeImage(v.data.imgs,j)">-->
<!--                    </div>-->
<!--                    <div class="remarks" v-if="v.remark"><p>备注:</p><textarea v-model="v.remark" readonly></textarea>-->
<!--                    </div>-->
<!--                    <div class="inputWrap">-->
<!--                        <div class="label">申请金额</div>-->
<!--                        <div class="inputBoxMoney">-->
<!--                            <span>￥</span><input type="number" v-model="v.amount" readonly>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;社群管理&ndash;&gt;-->
<!--            <div class="twoType" v-if="v.type==2">-->
<!--                <div class="wrap" :class="twotype?'wrapHeight':''">-->
<!--                    <div class="openImg" :class="twotype?'retractActive':'openactive'" @click="opentype(2)">-->
<!--                        <img :src="openImg" alt="">-->
<!--                    </div>-->
<!--                    <div class="typeTitle">-->
<!--                        <div><span>申请类型</span> <span class="name">社群管理</span></div>-->
<!--                        <div><span>管理社群数</span><input type="number" v-model="v.data.group_num" readonly></div>-->
<!--                    </div>-->
<!--                    <ul>-->
<!--                        <li v-for="(v1,j) in v.data.group_detail" :key="j">-->
<!--                            <div class="name">管理群{{j+1}}</div>-->
<!--                            <div class="inputbox">-->
<!--                                <span class="text">群名称</span><input type="text" v-model="v1.name" readonly>-->
<!--                            </div>-->
<!--                            <div class="inputbox">-->
<!--                                <span class="text1">群成员数量</span><input type="text" v-model="v1.num" readonly>-->
<!--                            </div>-->
<!--                            <div class="inputbox">-->
<!--                                <span class="text1">群管理角色</span><input type="text" v-model="v1.role" readonly>-->
<!--                            </div>-->
<!--                            <div class="uploadBox">-->
<!--                                <img :src="v2" alt="" v-for="(v2,n) in v1.imgs" :key="n" @click="seeImage(v1.imgs,n)">-->
<!--                            </div>-->
<!--                            <div class="remarks" v-if="v1.remark"><p>备注:</p><textarea v-model="v1.remark"-->
<!--                                                                                      readonly></textarea></div>-->
<!--                            <div class="inputWrap">-->
<!--                                <div class="label">申请金额</div>-->
<!--                                <div class="inputBoxMoney">-->
<!--                                    <span>￥</span><input type="number" v-model="v1.amount" readonly>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                    </ul>-->

<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;自媒体推广&ndash;&gt;-->
<!--            <div class="twoType" v-if="v.type==7">-->
<!--                <div class="wrap" :class="twotype?'wrapHeight':''">-->
<!--                    <div class="openImg" :class="twotype?'retractActive':'openactive'" @click="opentype(2)">-->
<!--                        <img :src="openImg" alt="">-->
<!--                    </div>-->
<!--                    <div class="typeTitle">-->
<!--                        <div><span>申请类型</span> <span class="name">自媒体推广</span></div>-->
<!--                        <div><span>直播场次数</span><input type="number" v-model="v.data.group_num" readonly></div>-->
<!--                    </div>-->
<!--                    <ul>-->
<!--                        <li v-for="(v1,j) in v.data.group_detail" :key="j">-->
<!--                            <div class="name">管理场{{j+1}}</div>-->
<!--                            <div class="inputbox">-->
<!--                                <span class="text1">直播间名称</span>-->
<!--                                <input type="text" v-model="v1.zb_name"  readonly>-->
<!--                            </div>-->
<!--                            <div class="inputbox" style="display: flex;">-->
<!--                                <span class="text1">直播开始时间</span>-->
<!--                                <input type="text" v-model="v1.start_time" readonly>-->
<!--                            </div>-->
<!--                            <div class="inputbox" style="display: flex;">-->
<!--                                <span class="text1">直播结束时间</span>-->
<!--                                <input type="text" v-model="v1.end_time" readonly>-->

<!--                            </div>-->
<!--                            <div class="inputbox">-->
<!--                                <span class="text1">观看人数</span><input type="text" v-model="v1.num" readonly>-->
<!--                            </div>-->
<!--                            <div class="uploadBox">-->
<!--                                <img :src="v2" alt="" v-for="(v2,n) in v1.imgs" :key="n" @click="seeImage(v1.imgs,n)">-->
<!--                            </div>-->
<!--                            <div class="remarks" v-if="v1.remark"><p>备注:</p><textarea v-model="v1.remark"-->
<!--                                                                                      readonly></textarea></div>-->
<!--                            <div class="inputWrap">-->
<!--                                <div class="label">申请金额</div>-->
<!--                                <div class="inputBoxMoney">-->
<!--                                    <span>￥</span><input type="number" v-model="v1.amount" readonly>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                    </ul>-->

<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;宣传物料&ndash;&gt;-->
<!--            <div class="onetype" v-if="v.type==4">-->
<!--                <div class="wrap" :class="fivetype?'wrapHeight':''">-->
<!--                    <div class="openImg " :class="fivetype?'retractActive':'openactive'" @click="opentype(5)">-->
<!--                        <img :src="openImg" alt="">-->
<!--                    </div>-->
<!--                    <div class="typeTitle">-->
<!--                        <span>申请类型</span> <span class="name">宣传物料</span>-->
<!--                    </div>-->
<!--                    <div class="uploadBox">-->
<!--                        <img :src="v1" alt="" v-for="(v1,j) in v.data.imgs" :key="j" @click="seeImage(v.data.imgs,j)">-->
<!--                    </div>-->

<!--                    <p class="desc">最多上传9张照片</p>-->
<!--                    <div class="remarks" v-if="v.remark"><p>备注:</p><textarea v-model="v.remark" readonly></textarea>-->
<!--                    </div>-->
<!--                    <div class="inputWrap">-->
<!--                        <div class="label">申请金额</div>-->
<!--                        <div class="inputBoxMoney">-->
<!--                            <span>￥</span><input type="number" v-model="v.amount" readonly>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;售后服务&ndash;&gt;-->
<!--            <div class="onetype" v-if="v.type==5">-->
<!--                <div class="wrap" :class="threetype?'wrapHeight':''">-->
<!--                    <div class="openImg " :class="threetype?'retractActive':'openactive'" @click="opentype(3)">-->
<!--                        <img :src="openImg" alt="">-->
<!--                    </div>-->
<!--                    <div class="typeTitle">-->
<!--                        <span>申请类型</span> <span class="name">售后服务</span>-->
<!--                    </div>-->
<!--                    <div class="uploadBox">-->
<!--                        <img :src="v1" alt="" v-for="(v1,j) in v.data.imgs" :key="j" @click="seeImage(v.data.imgs,j)">-->
<!--                    </div>-->
<!--                    <p class="desc">最多上传9张照片</p>-->
<!--                    <div class="remarks" v-if="v.remark"><p>备注:</p><textarea v-model="v.remark" readonly></textarea>-->
<!--                    </div>-->
<!--                    <div class="inputWrap">-->
<!--                        <div class="label">申请金额</div>-->
<!--                        <div class="inputBoxMoney">-->
<!--                            <span>￥</span><input type="number" v-model="v.amount" readonly>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;会议服务&ndash;&gt;-->
<!--            <div class="threeType" v-if="v.type==6">-->
<!--                <div class="wrap" :class="sixtype?'wrapHeight':''">-->
<!--                    <div class="openImg " :class="sixtype?'retractActive':'openactive'" @click="opentype(6)">-->
<!--                        <img :src="openImg" alt="">-->
<!--                    </div>-->
<!--                    <div class="typeTitle">-->
<!--                        <div><span>申请类型</span> <span class="name">会议服务</span></div>-->
<!--                        <van-radio-group v-model="v.meet_type" direction="horizontal" class="radioWrap" disabled>-->
<!--                            <van-radio name="conference">会务-->
<!--                                <template #icon="props">-->
<!--                                    <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>-->
<!--                                </template>-->
<!--                            </van-radio>-->
<!--                            <van-radio name="host">主持-->
<!--                                <template #icon="props">-->
<!--                                    <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>-->
<!--                                </template>-->
<!--                            </van-radio>-->
<!--                            <van-radio name="lecturer">讲师-->
<!--                                <template #icon="props">-->
<!--                                    <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>-->
<!--                                </template>-->
<!--                            </van-radio>-->
<!--                            <van-radio name="master">主办方-->
<!--                                <template #icon="props">-->
<!--                                    <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>-->
<!--                                </template>-->
<!--                            </van-radio>-->
<!--                        </van-radio-group>-->
<!--                        <div><span>场次数量</span><input type="number" v-model="v.data.group_num"></div>-->
<!--                    </div>-->
<!--                    <ul>-->
<!--                        <li v-for="(v1,j) in  v.data.group_detail" :key="j">-->
<!--                            <div class="name">场{{j+1}}</div>-->
<!--                            <div class="inputbox">-->
<!--                                <span class="text">会议名称</span><input type="text" readonly v-model="v1.name">-->
<!--                            </div>-->
<!--                            <div class="inputbox">-->
<!--                                <span class="text">会议人数</span><input type="number" readonly v-model="v1.num">-->
<!--                            </div>-->
<!--                            <div class="inputbox">-->
<!--                                <span class="text">会议地点</span><input type="text" readonly v-model="v1.address">-->
<!--                            </div>-->
<!--                            <div class="inputbox" v-if="v.meet_type=='lecturer'">-->
<!--                                <span class="text">讲课内容</span><input type="text" readonly v-model="v1.content">-->
<!--                            </div>-->
<!--                            <div class="uploadBox">-->
<!--                                <img :src="v2" alt="" v-for="(v2,n) in v1.imgs" :key="n" @click="seeImage(v1.imgs,n)">-->
<!--                            </div>-->
<!--                            <div class="uploadBox" v-if="v.meet_type=='master'">-->
<!--                                <p>签到表</p>-->
<!--                                <img :src="v1.sign_table" alt="">-->
<!--                            </div>-->
<!--                            <div class="desc">最多上传9张照片 <span v-if="v.data.type=='master'">(主办方需要上传签到表)</span> <span-->
<!--                                    v-if="v.data.type=='lecturer'">（课件内容请发送到公司邮箱）</span></div>-->
<!--                            <div class="remarks" v-if="v1.remark"><p>备注:</p><textarea v-model="v1.remark"-->
<!--                                                                                      readonly></textarea></div>-->
<!--                            <div class="inputWrap">-->
<!--                                <div class="label">申请金额</div>-->
<!--                                <div class="inputBoxMoney">-->
<!--                                    <span>￥</span><input type="number" v-model="v1.amount" readonly>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </li>-->

<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;<div class="onetype" v-if="v.type==3">&ndash;&gt;-->
<!--                &lt;!&ndash;<div class="wrap" :class="fourtype?'wrapHeight':''">&ndash;&gt;-->
<!--                    &lt;!&ndash;<div class="openImg " :class="fourtype?'retractActive':'openactive'" @click="opentype(4)">&ndash;&gt;-->
<!--                        &lt;!&ndash;<img :src="openImg" alt="">&ndash;&gt;-->
<!--                    &lt;!&ndash;</div>&ndash;&gt;-->
<!--                    &lt;!&ndash;<div class="typeTitle">&ndash;&gt;-->
<!--                        &lt;!&ndash;<span>申请类型</span> <span class="name">售前服务</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;</div>&ndash;&gt;-->
<!--                    &lt;!&ndash;<div class="uploadBox">&ndash;&gt;-->
<!--                        &lt;!&ndash;<img :src="v1" alt="" v-for="(v1,j) in v.data.imgs" :key="j">&ndash;&gt;-->
<!--                    &lt;!&ndash;</div>&ndash;&gt;-->
<!--                    &lt;!&ndash;<p class="desc">最多上传9张照片</p>&ndash;&gt;-->
<!--                    &lt;!&ndash;<div class="remarks" v-if="v.remark"><p>备注:</p><textarea v-model="v.remark" readonly></textarea>&ndash;&gt;-->
<!--                    &lt;!&ndash;</div>&ndash;&gt;-->
<!--                    &lt;!&ndash;<div class="inputWrap">&ndash;&gt;-->
<!--                        &lt;!&ndash;<div class="label">申请金额</div>&ndash;&gt;-->
<!--                        &lt;!&ndash;<div class="inputBoxMoney">&ndash;&gt;-->
<!--                            &lt;!&ndash;<span>￥</span><input type="number" v-model="v.amount" readonly>&ndash;&gt;-->
<!--                        &lt;!&ndash;</div>&ndash;&gt;-->
<!--                    &lt;!&ndash;</div>&ndash;&gt;-->
<!--                &lt;!&ndash;</div>&ndash;&gt;-->
<!--            &lt;!&ndash;</div>&ndash;&gt;-->





<!--        </div>-->
        <div class="applyInfo">
            <div class="title">
                收款信息
            </div>
            <div class="wrap">
                <ul>
                    <li>
                        <p class="label">合作方式</p><span>{{model[data.mode]}}</span>
                    </li>
                    <li>
                        <p class="label">收款账户</p><span>{{bankData.account_num}}</span>
                    </li>
                    <li>
                        <p class="label">开户银行</p><span>{{bankData.account_bank}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "@/api/api"
    import { ImagePreview } from "vant";
    // import pdf from 'vue-pdf'
    export default {
        data() {
            return {
                currentPage: 0, // pdf文件页码
                pageCount: 0, // pdf文件总页数
                fileType: 'pdf',
                openid: "oftPL6mLE4pyfl5-SijSk9JM_KPM",
                login: localStorage.getItem("login_token"),
                // openid:"oftPL6mLE4pyfl5-SijSk9JM_KPM",login:"gKm5AUr3FaMG74EUSYbE9kvb6K72c5yNdVtRxvSjuXdHeXrGFQ",
                realPrice: 0,
                ratePrice: 0,
                price: 0,
                actualMoney: "",
                model: ["", "个人", "企业"],
                type: ["", "电子发票", "纸质发票"],
                status1: [
                    "", "待审核", "", "待发放", "已发放", "审核驳回", "", "发放失败", "已取消", "发放中",
                ],
                status2: [
                    "", "待初审", "待复审", "待发放", "已发放", "初审驳回", "复审驳回", "发放失败", "已取消", "发放中",
                ],
                statusImg: [
                    "",
                    require("@/img/apply/icon_clock.png"),
                    require("@/img/apply/icon_clock.png"),
                    require("@/img/apply/icon_clock.png"),
                    require("@/img/apply/yifafang.png"),
                    require("@/img/apply/icon_sad.png"),
                    require("@/img/apply/icon_sad.png"),
                    require("@/img/apply/icon_sad.png"),
                    require("@/img/apply/yiquxiao.png"),
                    require("@/img/apply/fafangzhong.png"),
                ],
                user: "",
                bankInfo: "",
                bankId: 0,
                co_mode: 1,/* 合作方式 */
                bankList: [],
                number: 0,
                originNumberType: 0,
                originNumber: 0,/* 金额 */
                invoiceImg: require("@/img/apply/icon_xuanzhong.png"),
                invoiceImgDefault: require("@/img/apply/icon_weixuanzhong.png"),
                uploadInvoiceImg: require("@/img/apply/shangchuanzhizhifapiao.png"),
                uploadPdf: require("@/img/apply/shangchuanpdf.png"),
                openImg: require("@/img/apply/zhankai.png"),
                bankShow: false,/* 银行弹出框 */
                twoTypeNumber: 0,
                twoTypeindex: 0,
                fiveTypeNumber: 0,
                tip: "",
                sixTypeNumber: 0,
                typeInvoice: 1,
                onetype: false,
                twotype: false,
                threetype: false,
                fourtype: false,
                fivetype: false,
                sixtype: false,
                data: "",
                applyData: "",
                bankData: {},
                imageShow:false,
            }
        },
        methods: {
            //     changePdfPage (val) {
            //     // console.log(val)
            //     if (val === 0 && this.currentPage > 1) {
            //       this.currentPage--
            //       // console.log(this.currentPage)
            //     }
            //     if (val === 1 && this.currentPage < this.pageCount) {
            //       this.currentPage++
            //       // console.log(this.currentPage)
            //     }
            //   },
            //   // pdf加载时
            //   loadPdfHandler () {
            //     this.currentPage = 1 // 加载的时候先加载第一页
            //   },
            seeImage(images,index){
                ImagePreview({
                    images: images,
                    showIndex: true,
                    loop: false,
                    startPosition: index
                });
            },
            opentype(i) {
                switch (i) {
                    case 1:
                        this.onetype = !this.onetype;
                        break;
                    case 2:
                        this.twotype = !this.twotype;
                        break;
                    case 3:
                        this.threetype = !this.threetype;
                        break;
                    case 4:
                        this.fourtype = !this.fourtype;
                        break;
                    case 5:
                        this.fivetype = !this.fivetype;
                        break;
                    case 6:
                        this.sixtype = !this.sixtype;
                        break;
                }
            },
            modifyType(i) {
                this.typeInvoice = i
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            getdata(id) {
                var that = this
                // this.user = this.$store.state.WxUserInfo
                // this.openid = this.$store.state.openid
                this.$toast({
                    message: '加载中...',
                    forbidClick: true,
                })
                api.apply.getApplyInfoNew(this.openid, this.login, id).then(res => {
                    console.log(res)
                    this.$toast.clear()
                    if (res.data.code == 0) {
                        // let applyData = res.data.data.apply_data
                        that.bankId = res.data.data.apply_info.bank_id;
                        that.co_mode = res.data.data.apply_info.mode;
                        that.data = res.data.data.apply_info;
                        // for(var i=0;i<applyData.length;i++){
                        //     if(applyData[i].type==7){
                        //         for(var k=0;k<applyData[i].data.group_detail.length;k++){
                        //            var zb_time=applyData[i].data.group_detail[k].zb_time.split('-');
                        //             applyData[i].data.group_detail[k].start_time=zb_time[0];
                        //             applyData[i].data.group_detail[k].end_time=zb_time[1];
                        //
                        //         }
                        //     }
                        // }
                        // that.applyData = applyData;
                        that.bankList = res.data.data.bank_info
                        that.actualMoney = (that.data.apply_money - that.data.fee).toFixed(0)
                      let bankData = that.bankData
                        for (var i1 = 0; i1 < that.bankList.length; i1++) {
                            if (that.bankId == that.bankList[i1].id) {
                                bankData.account_bank = that.bankList[i1].account_bank;
                                bankData.account_num = that.bankList[i1].account_num;
                            }
                        }
                        console.log(bankData,1)
                      that.bankData = bankData
                        //  this.data.invoice_file = pdf.createLoadingTask(this.data.invoice_file)
                    } else {
                        this.$toast(res.data.msg)
                    }
                }).catch(req => {
                    console.log(req)
                    this.$toast.clear()
                })
            },
        },
        components: {
            // pdf
        },
        created() {
            let id = this.$route.query.id
            this.openid = this.$store.state.openid
            this.getdata(id)

        }
    }
</script>

<style lang="less">
    .apply {
        width: 100%;
        background-color: #f6f6f6;
        padding-top: 1rem;

        .status {
            width: 100%;
            box-sizing: border-box;
            padding: .4rem .8rem;
            display: flex;
            align-items: center;
            background-color: rgba(255, 247, 247, 1);
            font-size: .3rem;

            img {
                width: .36rem;
                margin-right: .24rem;
            }
        }

        .status1 {
            color: rgba(255, 168, 0, 1);
        }

        .status2 {
            color: rgba(255, 168, 0, 1);
        }

        .status3 {
            color: rgba(255, 168, 0, 1);
        }

        .status4 {
            color: rgba(13, 191, 0, 1);
        }

        .status5 {
            color: rgba(255, 24, 24, 1);
        }

        .status6 {
            color: rgba(255, 168, 0, 1);
        }

        .status7 {
            color: rgba(255, 24, 24, 1);
        }

        .status8 {
            color: rgba(219, 0, 255, 1);
        }

        .status9 {
            color: rgba(64, 81, 237, 1);
        }

        .applyInfo {
            width: 100%;

            .title {
                color: rgba(118, 117, 117, 1);
                font-size: .28rem;
                padding: .28rem 0 .14rem .26rem;
            }

            .wrap {
                width: 100%;
                background-color: #fff;
                padding: .38rem 1.04rem;
                box-sizing: border-box;

                ul {
                    li {
                        line-height: .64rem;

                        .label {
                            width: 1.5rem;
                            text-align: right;
                            display: inline-block;
                            color: rgba(157, 157, 157, 1);
                            font-size: .3rem;
                            margin-right: .5rem;
                        }

                        img {
                            width: 2.8rem;
                            height: 2rem;
                            object-fit: cover;
                            vertical-align: text-top;
                        }

                        a {
                            font-size: .3rem;
                        }
                    }
                }
            }
        }

        .sixTypes {
            width: 100%;
            background-color: #f6f6f6;

            .title {
                padding: .2rem 0 0 .24rem;
                font-size: .28rem;
                color: #767575;
                line-height: .6rem;
            }

            .onetype {
                width: 100%;
                background-color: #fff;
                margin-bottom: .18rem;

                .wrap {
                    position: relative;
                    padding: 0 0 .86rem .3rem;
                    box-sizing: border-box;
                    overflow: hidden;

                    .typeTitle {
                        span {
                            display: inline-block;
                            width: 2.8rem;
                            text-align: center;
                            line-height: 1.24rem;
                            font-size: .28rem;
                            color: #000000;
                            font-weight: bold;
                        }

                        .name {
                            text-align: left;
                            font-weight: 400;
                            color: rgba(74, 72, 72, 1);
                        }
                    }

                    .openImg {
                        position: absolute;
                        right: .5rem;
                        transition: all 1s;
                        width: .4rem;

                        img {
                            width: 100%;
                        }
                    }

                    .openactive {
                        bottom: .3rem;
                    }

                    .retractActive {
                        top: .4rem;
                        transform: rotate(180deg);
                    }

                    .uploadBox {
                        width: 100%;
                        padding-left: .3rem;
                        box-sizing: border-box;
                        margin-bottom: .46rem;

                        .van-uploader__preview-image {
                            width: 1.76rem;
                        }

                        img {
                            width: 1.76rem;
                            height: 1.76rem;
                            margin: .1rem;
                            object-fit: cover;
                        }
                    }

                    .remarks {
                        width: 100%;
                        padding: .3rem;
                        box-sizing: border-box;

                        p {
                            font-size: .3rem;
                            margin-bottom: .2rem;
                        }

                        textarea {
                            width: 90%;
                            height: 1.3rem;
                            font-size: .3rem;
                            border-radius: .1rem;
                            border: .02rem solid rgba(229, 227, 227, 0.6);
                            padding: .2rem;
                            box-sizing: border-box;
                        }
                    }

                    .inputWrap {
                        width: 100%;
                        padding: .3rem;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        .inputBoxMoney {
                            width: 4rem;
                            height: .8rem;
                            padding: .18rem .3rem;
                            background-color: rgba(255, 248, 248, 1);
                            box-sizing: border-box;
                            border: .02rem solid rgba(255, 228, 228, 1);
                            display: flex;
                            align-items: center;
                            font-size: .26rem;

                            input {
                                border: none;
                                width: 2rem;
                                background-color: rgba(255, 248, 248, 1);
                            }
                        }
                    }
                }

                .wrapHeight {
                    height: 1.22rem;
                }
            }

            .twoType {
                width: 100%;
                background-color: #fff;
                margin-bottom: .18rem;

                .wrap {
                    position: relative;
                    padding-bottom: .86rem;
                    box-sizing: border-box;
                    overflow: hidden;

                    .typeTitle {
                        padding: 0 0 0 .3rem;
                        box-sizing: border-box;

                        span {
                            display: inline-block;
                            width: 2rem;
                            line-height: 1.24rem;
                            font-size: .3rem;
                            color: #000000;
                            font-weight: bold;
                            padding-left: .3rem;
                        }

                        .name {
                            text-align: left;
                            font-weight: 400;
                            color: rgba(74, 72, 72, 1);
                            padding-left: 0;
                        }

                        input {
                            border: none;
                            width: 2rem;
                            font-size: .28rem;
                        }
                    }

                    .openImg {
                        position: absolute;
                        right: .5rem;
                        transition: all 1s;
                        width: .4rem;

                        img {
                            width: 100%;
                        }
                    }

                    .openactive {
                        bottom: .3rem;
                    }

                    .retractActive {
                        top: .4rem;
                        transform: rotate(180deg);
                    }

                    ul {
                        border-top: .02rem solid #f0eeee;
                        padding: 0 .48rem;
                        width: 100%;
                        box-sizing: border-box;

                        li {
                            border-bottom: .02rem solid #f0eeee;
                            padding: .2rem 0;
                            box-sizing: border-box;

                            .name {
                                padding-left: .34rem;
                                color: rgba(99, 79, 198, 1);
                                font-size: .28rem;
                            }

                            .inputbox {
                                width: 100%;
                                position: relative;
                                padding-left: .84rem;
                                margin: .32rem 0 .28rem 0;
                                box-sizing: border-box;
                                font-size: .28rem;

                                .text {
                                    position: relative;
                                    width: 1.56rem;
                                    display: inline-block;
                                }

                                .text::after {
                                    position: absolute;
                                    content: "*";
                                    right: .51rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                .text1 {
                                    position: relative;
                                    width: 1.9rem;
                                    display: inline-block;
                                }

                                .text1::after {
                                    position: absolute;
                                    content: "*";
                                    right: -.05rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                .text5 {
                                    position: relative;
                                    width: 2rem;
                                    display: inline-block;
                                }

                                .text5::after {
                                    position: absolute;
                                    content: "*";
                                    right: .51rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                .text52 {
                                    position: relative;
                                    width: 2rem;
                                    display: inline-block;
                                }

                                .text52::after {
                                    position: absolute;
                                    content: "*";
                                    right: -.05rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                input {
                                    border: none;
                                    margin-left: .2rem;
                                    width: 3rem;
                                    font-size: .28rem;
                                }
                            }

                            .inputbox::after {
                                position: absolute;
                                content: "";
                                left: .46rem;
                                top: .16rem;
                                width: .1rem;
                                height: .1rem;
                                background-color: rgba(255, 134, 92, 1);
                                border-radius: 50%;
                            }

                            .uploadBox {
                                width: 100%;
                                padding-left: .3rem;
                                box-sizing: border-box;

                                .van-uploader__preview-image {
                                    width: 1.76rem;
                                }

                                img {
                                    width: 1.76rem;
                                    height: 1.76rem;
                                    margin: .1rem;
                                    object-fit: cover;
                                }
                            }

                            .radioWrap {
                                padding: .3rem .5rem;
                                display: flex;
                                justify-content: space-between;

                                .activeIcon {
                                    display: inline-block;
                                    width: .32rem;
                                    height: .32rem;
                                    border-radius: 50%;
                                    border: .02rem solid rgba(133, 128, 128, 1);
                                    box-sizing: border-box;
                                }

                                .inactiveIcon {
                                    display: inline-block;
                                    position: relative;
                                    width: .32rem;
                                    height: .32rem;
                                    border-radius: 50%;
                                    box-sizing: border-box;
                                    background: rgba(241, 82, 35, 1);
                                }

                                .inactiveIcon::after {
                                    position: absolute;
                                    content: '';
                                    width: .28rem;
                                    height: .28rem;
                                    border-radius: 50%;
                                    box-sizing: border-box;
                                    background: rgba(241, 82, 35, 1);
                                    border: .04rem solid #fff;
                                    left: 0;
                                    right: 0;
                                    margin: 0 auto;
                                    top: .02rem;
                                }
                            }

                            .inputWrap {
                                width: 100%;
                                padding: .3rem;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;

                                .inputBoxMoney {
                                    width: 4rem;
                                    height: .8rem;
                                    padding: .18rem .3rem;
                                    background-color: rgba(255, 248, 248, 1);
                                    box-sizing: border-box;
                                    border: .02rem solid rgba(255, 228, 228, 1);
                                    display: flex;
                                    align-items: center;
                                    font-size: .26rem;

                                    input {
                                        border: none;
                                        width: 2rem;
                                        background-color: rgba(255, 248, 248, 1);
                                    }
                                }
                            }
                        }
                    }

                    .remarks {
                        width: 100%;
                        padding: .3rem .4rem;
                        box-sizing: border-box;

                        p {
                            font-size: .3rem;
                            margin-bottom: .2rem;
                        }

                        textarea {
                            width: 100%;
                            height: 1.3rem;
                            font-size: .3rem;
                            border-radius: .1rem;
                            border: .02rem solid rgba(229, 227, 227, 0.6);
                            padding: .2rem;
                            box-sizing: border-box;
                        }
                    }
                }

                .wrapHeight {
                    height: 2.6rem;
                }
            }

            .threeType {
                width: 100%;
                background-color: #fff;
                margin-bottom: .18rem;

                .wrap {
                    position: relative;
                    padding-bottom: .86rem;
                    box-sizing: border-box;
                    overflow: hidden;

                    .typeTitle {
                        padding: 0 0 0 .3rem;
                        box-sizing: border-box;

                        span {
                            display: inline-block;
                            width: 2rem;
                            line-height: 1.24rem;
                            font-size: .3rem;
                            color: #000000;
                            font-weight: bold;
                            padding-left: .3rem;
                        }

                        .name {
                            text-align: left;
                            font-weight: 400;
                            color: rgba(74, 72, 72, 1);
                            padding-left: 0;
                        }

                        input {
                            border: none;
                            width: 2rem;
                        }

                        .activeIcon {
                            display: inline-block;
                            width: .32rem;
                            height: .32rem;
                            border-radius: 50%;
                            border: .02rem solid rgba(133, 128, 128, 1);
                            box-sizing: border-box;
                        }

                        .inactiveIcon {
                            display: inline-block;
                            position: relative;
                            width: .32rem;
                            height: .32rem;
                            border-radius: 50%;
                            box-sizing: border-box;
                            background: rgba(241, 82, 35, 1);
                        }

                        .inactiveIcon::after {
                            position: absolute;
                            content: '';
                            width: .28rem;
                            height: .28rem;
                            border-radius: 50%;
                            box-sizing: border-box;
                            background: rgba(241, 82, 35, 1);
                            border: .04rem solid #fff;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            top: .02rem;
                        }

                        .radioWrap {
                            justify-content: space-around;
                        }

                        .van-radio__label {
                            width: auto;
                        }
                    }

                    .openImg {
                        position: absolute;
                        right: .5rem;
                        transition: all 1s;
                        width: .4rem;

                        img {
                            width: 100%;
                        }
                    }

                    .openactive {
                        bottom: .3rem;
                    }

                    .retractActive {
                        top: .4rem;
                        transform: rotate(180deg);
                    }

                    ul {
                        border-top: .02rem solid #f0eeee;
                        padding: 0 .48rem;
                        width: 100%;
                        box-sizing: border-box;

                        li {
                            border-bottom: .02rem solid #f0eeee;
                            padding: .3rem 0;
                            box-sizing: border-box;

                            .name {
                                padding-left: .64rem;
                                color: rgba(99, 79, 198, 1);
                                font-size: .28rem;
                            }

                            .inputbox {
                                width: 100%;
                                position: relative;
                                padding-left: .84rem;
                                margin: .32rem 0 .28rem 0;
                                box-sizing: border-box;
                                font-size: .28rem;

                                .text {
                                    position: relative;
                                    width: 1.56rem;
                                    display: inline-block;
                                }

                                .text::after {
                                    position: absolute;
                                    content: "*";
                                    right: 0rem;
                                    top: .12rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                input {
                                    border: none;
                                    margin-left: .2rem;
                                    width: 3rem;
                                    font-size: .28rem;
                                }
                            }

                            .inputbox::after {
                                position: absolute;
                                content: "";
                                left: .46rem;
                                top: .16rem;
                                width: .1rem;
                                height: .1rem;
                                background-color: rgba(255, 134, 92, 1);
                                border-radius: 50%;
                            }

                            .uploadBox {
                                width: 100%;
                                padding-left: .3rem;
                                box-sizing: border-box;

                                .van-uploader__preview-image {
                                    width: 1.76rem;
                                }

                                img {
                                    width: 1.76rem;
                                    height: 1.76rem;
                                    margin: .1rem;
                                    object-fit: cover;
                                }

                                p {
                                    font-size: .3rem;
                                    line-height: .6rem;
                                    font-weight: bold;
                                }
                            }

                            .inputWrap {
                                width: 100%;
                                padding: .3rem;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;

                                .inputBoxMoney {
                                    width: 4rem;
                                    height: .8rem;
                                    padding: .18rem .3rem;
                                    background-color: rgba(255, 248, 248, 1);
                                    box-sizing: border-box;
                                    border: .02rem solid rgba(255, 228, 228, 1);
                                    display: flex;
                                    align-items: center;
                                    font-size: .26rem;

                                    input {
                                        border: none;
                                        width: 2rem;
                                        background-color: rgba(255, 248, 248, 1);
                                    }
                                }
                            }
                        }
                    }

                    .remarks {
                        width: 100%;
                        padding: .3rem .4rem;
                        box-sizing: border-box;

                        p {
                            font-size: .3rem;
                            margin-bottom: .2rem;
                        }

                        textarea {
                            width: 100%;
                            height: 1.3rem;
                            font-size: .3rem;
                            border-radius: .1rem;
                            border: .02rem solid rgba(229, 227, 227, 0.6);
                            padding: .2rem;
                            box-sizing: border-box;
                        }
                    }
                }

                .wrapHeight {
                    height: 3.9rem;
                }
            }

            .desc {
                font-size: .26rem;
                color: #C9C9C9;
                padding-left: .3rem;

                span {
                    color: rgba(241, 82, 35, 1);
                }
            }
        }

        .btnBox {
            width: 5.86rem;
            margin: .1rem auto;
            font-size: .32rem;
        }

        .bankList {
            width: 100%;
            padding-top: 1rem;

            li {
                width: 100%;
                box-sizing: border-box;
                padding: 0 .8rem;
                line-height: 1.1rem;
                font-size: .32rem;
                color: #000;
                border-bottom: .02rem solid rgba(229, 227, 227, 0.6);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }

        .submitPopupBox {
            padding: 0 .3rem .3rem .3rem;
            box-sizing: border-box;

            .title {
                color: #000;
                text-align: center;

                .name {
                    font-size: .32rem;
                }

                .price {
                    font-size: .44rem;
                    margin: .3rem 0 .5rem 0;
                }
            }

            ul {
                li {
                    display: flex;
                    justify-content: space-between;
                    font-size: .28rem;
                    color: #000;
                    margin-bottom: .26rem;
                }
            }

            .tip {
                font-size: .22rem;
                color: rgba(253, 32, 32, 1);
                width: 100%;
                text-align: center;
                margin: .46rem 0 .7rem 0;
            }

            .submitBtn {
                width: 3.6rem;
                line-height: .6rem;
                background-color: rgba(241, 82, 35, 1);
                border-radius: .1rem;
                text-align: center;
                margin: 0 auto;
                color: #fff;
            }
        }

    }
</style>